<template>
  <v-dialog
    :value="dlg.visible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      class="blue-grey lighten-5"
      flat
    >
      <v-card-title
        class="white"
      >
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <div class="co-flex-row co-items-center">
          <v-btn
            depressed
            @click="toPrev"
          >
            {{ btnPrev.text }}
          </v-btn>
          <v-btn
            depressed
            class="ml-4"
            @click="toNext"
          >
            {{ btnNext.text }}
          </v-btn>
        </div>
        <v-spacer />
        <div class="co-flex-row co-items-center">
          <div class="mr-10">
            <span class="co-text-md co-text-gray-800">{{ current + 1 }}</span>
            <span class="co-text-md px-1 co-text-gray-400">/</span>
            <span class="co-text-md co-text-gray-500">{{ count }}</span>
          </div>
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>
              {{ btnClose.icon }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider />
      <v-card
        class="overflow-y-auto overflow-x-hidden blue-grey lighten-5 pa-6"
        flat
        max-height="900"
        width="100%"
      >
        <v-img
          v-if="items[current]"
          :src="items[current]"
          width="100%"
          cover
        />
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
    },
    data () {
      return {
        dlg: {
          title: '图片预览',
          visible: false
        },
        btnClose: {
          icon: 'mdi-close'
        },
        btnPrev: {
          disalbed: false,
          text: '上一张'
        },
        btnNext: {
          disalbed: false,
          text: '下一张'
        },
        items: [],
        count: 0,
        current: 0
      }
    },
    methods: {
      toPrev () {
        let index = this.current
        index -= 1
        if (index < 0) {
          index = this.count - 1
        }

        this.current = index
      },
      toNext () {
        let index = this.current
        index += 1
        if (index >= this.count) {
          index = 0
        }
        this.current = index
      },
      show (imgs, start = 0) {
        this.items = imgs || []
        this.current = start
        this.count = this.items.length

        this.dlg.visible = true
      },
      onClose (ev) {
        this.dlg.visible = false
      },
    }
  }
</script>
